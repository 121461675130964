/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */
/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;
/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import * as process from 'process';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your dashboard
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */
/***************************************************************************************************
 * BROWSER POLYFILLS
 */ // Run `npm install --save web-animations-js`

// ISSUE: https://github.com/angular/angular/issues/45016
if (
    !('animate' in document.documentElement) ||
    (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent))
) {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/web-animations-js@2.3.2';
    document.head.appendChild(script);
}

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

// ISSUE: https://stackoverflow.com/questions/50313745/angular-6-process-is-not-defined-when-trying-to-serve-application
window['process'] = process;

// ISSUE: https://stackoverflow.com/questions/54349858/angular-7-uncaught-referenceerror-global-is-not-defined-when-adding-package/54352978
(window as any)['global'] = window;
